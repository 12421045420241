<template>
  <div class="frost-form ml-3 mr-3">
    <div class="custom-date flex-column">
      <span class="control-label">Select Date</span>
      <ejs-datepicker cssClass="date-picker" :value="history.start" :max="Date.now()" @change="handleDateChange"
        placeholder="Start Date" :width="'210px'" />
    </div>
    <div class="radio-buttons flex-column">
      <span class="control-label">Time Period</span>
      <div>
        <label>
          <input type="radio" label="1 Day" labelPosition="Before" :checked="period === 'day'"
            @change="() => updatePeriod('day')" />
          1 Day
        </label>
        <label>
          <input type="radio" label="1 Week" labelPosition="Before" :checked="period === 'week'"
            @change="() => updatePeriod('week')" />
          1 Week
        </label>
        <label>
          <input type="radio" label="1 Month" labelPosition="Before" :checked="period === 'month'"
            @change="() => updatePeriod('month')" />
          1 Month
        </label>
      </div>
    </div>
    <div class="dm-flex-row dm-margin-left-sm">
      <ejs-button @click.native="handleExportHistory" cssClass="btn btn-secondary export-button"
        :disabled="historyBtnDisabled">
        <i class="fa fa-download"></i> <span>Quick Export</span>
      </ejs-button>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import moment from 'moment-timezone';
import { useHistoryStore } from '../../js/store';

const history = useHistoryStore();

const props = defineProps({
  deviceId: {
    type: String,
    required: true
  },
  v2: {
    type: Boolean,
    required: false,
    default: false
  }
});

const period = ref('week');
const userModifiedDateInput = ref(false)

const updatePeriod = (updatedPeriod) => {
  period.value = updatedPeriod;
  // If the user has not modified the date input then we should use default date ranges
  if (!userModifiedDateInput.value) {
    history.updateEnd(Date.now());
    history.updateStart(moment(Date.now()).subtract(1, updatedPeriod));
    history.getHistory(props.deviceId);
    return;
  }
  // If they have then we will keep the date they selected
  const newEnd = moment(history.start).add(1, updatedPeriod);
  if (newEnd.isAfter(Date.now())) {
    history.updateEnd(Date.now());
    history.updateStart(moment(Date.now()).subtract(1, updatedPeriod));
  } else {
    history.updateEnd(newEnd);
  }
  history.getHistory(props.deviceId);
};

const handleDateChange = (e) => {
  // If the user has triggered an event, then we will record that they modified the date input
  if (e.event !== null) {
    userModifiedDateInput.value = true;
  }
  const newStart = moment(e.value);
  const newEnd = moment(e.value).add(1, period.value);
  if (newEnd.isAfter(Date.now())) {
    history.updateEnd(Date.now());
    history.updateStart(moment(Date.now()).subtract(1, period.value));
  } else {
    history.updateStart(newStart);
    history.updateEnd(newEnd);
  }
  if (props.v2 === true) {
    history.getHistoryV2(props.deviceId);
  } else {
    history.getHistory(props.deviceId);
  }
};

const handleExportHistory = () => {
  history.exportHistory(props.deviceId);
};

const historyBtnDisabled = computed(() => {
  return history.transmissions[props.deviceId]?.length === 0 || history.loading;
});
</script>

<style>
.export-button:disabled {
  background-color: #5b5b5b !important;
  color: #ffffff !important;
}

.interval-control {
  margin-bottom: 4px;
  margin-top: 16px;
  padding-top: 0;
}

.interval-units {
  height: 30px;
  display: inline-block;
  vertical-align: bottom;
}
</style>